import "../../App.css";
import { Button } from "../Button";
import CurrentMint from "../CurrentMint";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { disconnect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import empty from "../metadata/metadata.json";
import $ from "jquery";
import { render } from "@testing-library/react";
import searchIcon from "../assets/images/search.png";
import html2canvas from 'html2canvas';
import Modal from "react-modal";

const random = (min = 1, max = 4444) => {
  let num = Math.random() * (max - min) + min;

  return Math.floor(num);
};
setInterval(random(), 500);

const imgDapp =
  "https://kishu.mypinata.cloud/ipfs/QmRwe5pZTQjm8Ph59KPjj9Aq93z3ygi61Xz5ba4rozHWBy/";
const jpg = ".jpg";

function Rarity() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
      xx(blockchain.walletOfOwner);
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  console.table(data);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [imgSrc, setSrc] = useState("images/Random.png");
  const [uri, setUri] = useState(empty);
  const [nftId, setNftId] = useState(8888);
  const [rank, setRank] = useState("Common");
  let [x, xx] = useState("nothing");
  const [commonHidden, setCommonHidden] = useState("none");
  const [rareHidden, setRareHidden] = useState("none");
  const [legendaryHidden, setLegendaryHidden] = useState("none");
  const [ultralegendaryHidden, setultraLegendaryHidden] = useState("none");
  const [lable, setLable] = useState("none");
  const [checkOwner, setCheckOwner] = useState("none");
  const [rounds, setRounds] = useState("0");
  const opensea = "https://opensea.io/assets/0x0be5dd8dc316a936942b1f58667c9ea1ebb71e07/"
  const currentSelection = nftId + 1;
  const OpenseaLink = opensea+currentSelection;
  const [shareButton, setShareButton] = useState("none");
  const [modalVisible, setModalVisible] = useState("none");
  const [screenShot, setScreenShot] = useState("images/Random.png");

  console.log(currentSelection);

useEffect(() => {
  setRounds(round);
  if (rounds == 0){
    setRank("");
    setCommonHidden("none");
    setRareHidden("none");
    setLegendaryHidden("none");
    setultraLegendaryHidden("none");
  }
  else if (rounds <= 7000 ){
    setRank("Common");
    setCommonHidden("");
    setRareHidden("none");
    setLegendaryHidden("none");
    setultraLegendaryHidden("none");
   } else if (rounds <= 8000 && round > 7000){
     setRank("Rare");
     setCommonHidden("none");
     setRareHidden("");
     setLegendaryHidden("none");
     setultraLegendaryHidden("none");
   } else if (rounds <= 9000 && round > 8000){
     setRank("Legendary");
     setCommonHidden("none");
     setRareHidden("none");
     setLegendaryHidden("");
     setultraLegendaryHidden("none");
   } else if (rounds <= 12000 && round > 9000){
     setRank("Ultra Legendary");
     setultraLegendaryHidden("");
     setCommonHidden("none");
     setRareHidden("none");
     setLegendaryHidden("none");
   };
})

  const totalRarity1 = uri[nftId].attributes.reduce((total, post) =>{
    return total + post.Rarity;
  }, 0);

  const round = Number(totalRarity1).toFixed(0);


 function capture() {
  html2canvas(document.querySelector("#capture"), {allowTaint:true, useCORS: true}).then(canvas => {
    var link = document.createElement("a");
			document.body.appendChild(link);
			link.href = canvas.toDataURL("image/png");
			link.target = './';
      console.log(link.href);
      setScreenShot(link.href);
});
};

  return (
    <div className="mint__container">
      <>
        {blockchain.account === "" || blockchain.smartContract === null ? (
          <div className="mint__container">
            <div className="sign">
              <div className="fast-flicker">R</div>
              <div className="flicker">AR</div>
              <div className="fast-flicker">ITY</div>
            </div>
            <div className="connect__wrapper">
              <CurrentMint src="images/Random.png" />

              <Button
                className={"btn--11"}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                CONNECT
              </Button>

              {blockchain.errorMsg !== "" ? (
                <>
                  <div
                    className="cards__item__note"
                    style={{ textAlign: "center" }}
                  >
                    {blockchain.errorMsg}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : (
          //Connected
          
          <div className="mint__container">
            <div className="connect__wrapper">
              <div
                className="cards__item__note3"
                style={{ textAlign: "center" }}
              >
                Connected Wallet : {blockchain.account}
              </div>
              <Button
                className={"btn--11"}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(disconnect());
                  getData();
                  
                }}
              >
                Disconnect
              </Button>
              <div>
                <div className="nft__wrapper">
                  {data.walletOfOwner.map((item) => {
                    return [
                      <div className="nft2__wrapper">
                        <button
                          className="btnn"
                          onClick={() => {
                            setSrc(imgDapp + item + jpg);
                            setNftId(item - 1);
                            document.getElementsByClassName("trait__wrapper")[0].style.display = "";
                            document.getElementsByClassName("trait__wrapper")[1].style.display = "";
                            document.getElementsByClassName("trait__wrapper")[2].style.display = "";
                            document.getElementsByClassName("trait__wrapper")[3].style.display = "";
                            document.getElementsByClassName("trait__wrapper")[4].style.display = "";
                            document.getElementsByClassName("trait__wrapper")[5].style.display = "";
                            document.getElementsByClassName("trait__wrapper")[6].style.display = "";
                            setShareButton("");
                            setLable("");
                          }}
                          type="text"
                        >
                          <img
                            className="picture__item3__img"
                            src={imgDapp + item + jpg}
                            loading='lazy'
                          ></img>
                        </button>
                      </div>,
                      <div className="nft2__wrapper">
                        <s.TextTitle
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          KISHUVERSE #{item}
                        </s.TextTitle>
                      </div>,
                    ];
                  })}
                </div>
                {Number(data.totalSupply) !== 8888 ? (
                  <Button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/Mint";
                    }}
                  >Mint Kishuverse NFT</Button>
                  ) : (
                  <Button id='myButton'
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("https://opensea.io/collection/kishuverse", "_blank");
                    }}
                  >
                    Check Kishuverse on Opensea
                    </Button>
                  )}
              </div>
              {/* Start Search Bar */}
            <div className="search__wrapper">
              <input type="number" min={1} max={data.totalSupply} className="searchbar" placeholder="Search kishuverse using NFT ID.." id="searchbar"></input>
              <button
              id="searchButton"
              className="search__button"
              onClick={() => {

                if  ((Number(8888) < document.getElementById("searchbar").value))
                {
                   alert("Total supply is 8888!");
                 }

                else if ((document.getElementById("searchbar").value) > Number(data.totalSupply)) {
                  alert("Not Minted yet!");
                } 

                else if ((document.getElementById("searchbar").value) == 0 || document.getElementById("searchbar").value == "") {
                  alert("Please input a number from 1 to 8888!");
                } 

                else if ((document.getElementById("searchbar").value) <= Number(data.totalSupply)) {
                setSrc(imgDapp + (document.getElementById("searchbar").value) + jpg);
                setNftId((document.getElementById("searchbar").value) - 1);
                document.getElementsByClassName("trait__wrapper")[0].style.display = "";
                document.getElementsByClassName("trait__wrapper")[1].style.display = "";
                document.getElementsByClassName("trait__wrapper")[2].style.display = "";
                document.getElementsByClassName("trait__wrapper")[3].style.display = "";
                document.getElementsByClassName("trait__wrapper")[4].style.display = "";
                document.getElementsByClassName("trait__wrapper")[5].style.display = "";
                document.getElementsByClassName("trait__wrapper")[6].style.display = "";
                setShareButton("");
                setLable("");} 
              }}
              ><img className="icon__small" src={searchIcon}></img></button>
              </div>
              {/* End Search Bar */}
            </div>
            <div className="connect__wrapper" id="capture">
              {/* Start NFT Picture */}
              <div className="top-left" id="img_lable" style={{ display : lable }}>Kishuverse #{nftId + 1}</div>
              <div className="bottom-center" id="img_owner" style={{ display : lable }}>{blockchain.ownerOf}</div>
              <img className="img" className="modal__img" src={imgSrc}></img>
              {/* End NFT Picture */}
              {/* Start Traits Mapping */}

              {uri[nftId].attributes.map((post) => {
                return [
                  <div className="trait__wrapper" style={{ display: "none"}}>
                    <div className="nftTrait__wrapper">
                      <div id="Oh" className="nft3__wrapper-text">
                        {post.trait_type}
                      </div>
                    </div>
                    <div className="nftName__wrapper">
                      <div className="nft3__wrapper-text" > 
                      :
                      </div>
                    </div>
                    <div className="nftName__wrapper">
                      <div className="nft3__wrapper-text" > 
                      {post.value}
                      </div>
                    </div>
                    <div id="Rarity" className="nftScore__wrapper">
                        {post.Rarity/10}
                    </div>
                  </div>
                ];
              })}
              {/* End Traits Mapping */}
              {/* Start Rarity */}
              <div className="common__wrapper" id="commonwrapper" style={{ display: commonHidden, backgroundColor:"#fff"}}>
                <div className="nft4__wrapper-text" id="common" style={{ display: commonHidden, backgroundColor:"transparent", color:"black"}}>
                Rarity Score : {round/10} ( {rank} )
            </div>
            </div>
            <div className="rare__wrapper" id="rarewrapper" style={{display: rareHidden, backgroundColor:"#fff"}}>
                <div className="nft4__wrapper-text" id="rare" style={{ display: rareHidden, backgroundColor:"transparent", color:"white"}}>
              Rarity Score : {round/10} ( {rank} )
            </div>
            </div>
            <div className="legendary__wrapper" id="legendarywrapper" style={{display: legendaryHidden, backgroundColor:"#fff"}}>
                <div className="nft4__wrapper-text" id="legendary" style={{ display: legendaryHidden, backgroundColor:"transparent", color:"white"}}>
                Rarity Score : {round/10} ( {rank} )
            </div>
            </div>
            <div className="ultralegendary__wrapper" id="ultralegendarywrapper" style={{display: ultralegendaryHidden, backgroundColor:"#fff"}}>
                <div className="nft4__wrapper-text" id="ultralegendary" style={{ display: ultralegendaryHidden, backgroundColor:"transparent", color:"white"}}>
                Rarity Score : {round/10} ( {rank} )
            </div>
            </div>
            
            </div>
            {/* End Rarity */}
            <div className="share__wrapper" style={{display: shareButton}}>
            <button 
              className="opensea__button"
              onClick={(e) => {
                e.preventDefault();
                setModalVisible("");
                capture();
              }}
              >Snapshot</button>
            <button 
              className="opensea__button"
              onClick={(e) => {
                e.preventDefault();
                window.open(OpenseaLink, "_blank");
              }}
              >OpenSea</button></div>
              
              <div
              className="modal"
              id="exportModal"
              style={{display:modalVisible}}>
                <div
                className="modal__wrapper"
                >
                <button
                className="modal__close"
                onClick={(e) => {
                  e.preventDefault();
                  setModalVisible("none");
                  setScreenShot("images/Random.png")
                }}
                >
                  <img
                  className="modal__close"
                  src="images/close.png"
                  ></img>
                </button>
                <img
                className="modal__img2"
                src={screenShot}
                 />
              </div>
              </div>
          </div>
          
        )}
      </>
    </div>
  );
}
export default Rarity;
