import '../../App.css';
import { Button } from '../Button';
import CurrentMint from '../CurrentMint';
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { disconnect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import i1 from "../assets/images/1.gif"; 
import styled from "styled-components";

const random = (min = 1, max = 7000) => {
  let num = Math.random() * (max - min) + min;

  return Math.floor(num);
};
setInterval(random(), 500);

const mintedLink = "https://kishu.mypinata.cloud/ipfs/QmRwe5pZTQjm8Ph59KPjj9Aq93z3ygi61Xz5ba4rozHWBy/";
const mintedLink2 = "https://ipfs.io/ipfs/QmcmgxoPx6XvUNCK77JPDQjUfJRkVXBj4K2q8myP6d6F4Y/";
const png = ".jpg";

export const StyledField = styled.input`
  padding: 10px;
  border-radius: 0px;
  border: none;
  background-color: #1D1D1D;
  font-weight: bold;
  color: #ffffff;
  width: 100%;
  cursor: arrow;;
  text-align: center;
`;

function Mint() {
  
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Price : 0.038 ETH Excluding Gas Fee.");
  const [claimingNft, setClaimingNft] = useState(false);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: ("285000"* _amount),
        to: "0x0be5dd8Dc316a936942B1f58667c9ea1Ebb71E07",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((+(0.038 * _amount).toFixed(8)).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Welcome to Kishuverse, your NFT is on Opensea.io now."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

const myDiv = 1;
if ( null == myDiv ) {
  myDiv.value = document.getElementById('Mi');
}

  return (
      <div className='mint__container'>

            {Number(data.totalSupply) == 8888 ? (
              <>
                <div className='cards__item__header' style={{ textAlign: "center"}}>
                  The sale has ended.
                </div>
                <s.TextDescription style={{ textAlign: "center"}}>
                  You can still find Kishuverse on{" "}
                  <a
                    target={"_blank"}
                    href={"https://opensea.io/collection/kishuverse"}
                  >
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : ( //Not Connected
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <div className='mint__container'>
                    <div class="sign">
                      <div className='fast-flicker'>
                    M
                    </div>
                    <div className='flicker'>
                    IN
                    </div>
                    <div className='fast-flicker'>
                    T
                    </div>
                    </div>
                  <div className='connect__wrapper'>
                    <CurrentMint loading='lazy'  src={mintedLink+random()+png} />
                    <Button className={'btn--11'} onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}>
                      CONNECT
                    </Button>

                    {blockchain.errorMsg !== "" ? (
                      <>

                        <div className='cards__item__note' style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </div>

                      </>
                    ) : null}
                  </div>
                  </div>
                ) : ( //Connected
                  <div className='mint__container'>
                    
                  <div className='connect__wrapper'>
                  <div className='cards__item__note3' style={{ textAlign: "center" }}>
                      Connected Wallet : {blockchain.account}
                    </div>
                    <Button className={'btn--11'} onClick={(e) => {
                        e.preventDefault();
                        dispatch(disconnect());
                        getData();
                        
                      }}>
                      Disconnect
                    </Button>
                    <div className="top-left2" id="img_lable">*Random Kishuverse NFT</div>
                    <CurrentMint
                  src={mintedLink+random()+png}
                  loading='lazy'
                  />
                            <s.TextTitle
                    style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}
                    >
                    {data.totalSupply}/8888
                    </s.TextTitle>
                    <div className='cards__item__note3' style={{ textAlign: "center" }}>
                      Amount :
                    </div>
                    <input id= "Mi" type='range' max={10} min={1} defaultValue={1}>
                    </input>
                    <img className='img'
                    
                  src='images/img-14.jpg'
                  ></img>
                    <Button
                      disabled={claimingNft ? document.getElementById("Mi").value : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(document.getElementById("Mi").value);
                        getData();
                      }}
                    >
                      {claimingNft ? "Please Wait" : "MINT"}
                    </Button>
                    <div className='cards__item__note2' style={{ textAlign: "center" }}>
                    {feedback}
                        </div>
                        <div className='cards__item__note3' style={{ textAlign: "center" }}>
                      * gas fees will increase according to the amount
                    </div>
                    <div className='cards__item__note3' style={{ textAlign: "center" }}>
                      * max 10 NFTs per transaction
                    </div>
                    </div>
                    </div>
                )}
              </>
            )}
      </div> 
  );
}
export default Mint;
